.Profile {
    display: flex;
    column-gap: 1rem;
    flex-wrap: wrap;
    flex-direction: row;
    height: 4rem;
}
.Profile .Avatar {align-self: center;}
.Profile .Avatar .avatarIcon {color: var(--mp-primary-color); height: 1.5rem}

.Profile .UserInfo {
    color: var(--mp-secondary-color);
    text-align: left;
    align-self: center;
}

.Profile .UserType { font-size: 0.7rem; }