.NavigationDrawer .Toolbar{
    min-height: 4rem;
    padding-right: 1rem;
    padding-left: 1rem;
    border-bottom: 1px solid lightgray;
}
.NavigationDrawer .Logo img{ height: 4rem;}
.NavigationDrawer .dx-icon-menu { color: var(--mp-primary-color) !important;}
.NavigationDrawer .TSignoutButton {
    background-color: red;
}

.SignoutButton {
    background-color: var(--mp-primary-color) !important;
    border-color: transparent;
    color: white !important;
    margin-left: 1rem;
}
.NavigationDrawer .dx-drawer-panel-content {background-color: rgb(53, 168, 224, 0.14);}
.NavigationList {width: 200px;}
.NavigationList .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item .dx-icon {color: var(--mp-primary-color)}
.NavigationList .dx-list-item {border-top: unset}
.NavigationList .dx-item-content {color: var(--mp-primary-color); border-bottom: 1px solid var(--mp-secondary-color)}