.NavBar {
    color: var(--mp-secondary-color);
    width:fit-content;
    margin:auto;
}
.NavBar ul {
    text-align: center;
    list-style-type: none;
    overflow: hidden;
    padding-left:0;
}
.NavBar li {
    display:inline-flex;
    background: #00A1B5 0% 0% no-repeat padding-box;
}

.NavBar li a{
    display: block;
    color: var(--mp-secondary-color);
    text-align: center;
    text-decoration: none;
}
.NavBar li a:hover {background: #007C83 0% 0% no-repeat padding-box;}
.NavBar li {background-color: white;}

.NavBar li a {padding: 8px 20px 8px 0px;}
.NavBar  li a:hover {background-color: white;}
.NavBar li a::before {
    margin-right:20px;
    margin-left:0;
}

.dx-drawer {
  height: 100vh;
}
