/*Loading Indicator*/
.ld-ind {
    display: inline-block;
    position: relative;
    min-width: 4rem;
    min-height: 4rem;
}
.ld-ind div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 3rem;
    height: 3rem;
    margin: 0.5rem;
    border: 0.5rem solid #fff;
    border-radius: 50%;
    animation: ld-ind 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--mp-primary-color) transparent transparent transparent;
}
.ld-ind div:nth-child(1) {animation-delay: -0.45s;}
.ld-ind div:nth-child(2) {animation-delay: -0.3s;}
.ld-ind div:nth-child(3) {animation-delay: -0.15s;}
@keyframes ld-ind {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}
/*Loading Indicator end*/