.App {
  min-height: 100vh;
  height: 100vh;
}

:root {
  --content-wrapper-width: 70em;
  --mp-primary-color: #3f5d64;
  --mp-secondary-color: #35a8e0;
  --mp-warning-color: #ffc107;
  --mp-confirmation-color: #198754;
  --mp-text-color-dark: black;
  --mp-text-color-bright: white;
  --mp-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --mp-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --mp-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --mp-body-font-family: var(--mp-font-sans-serif);
  --amplify-components-button-primary-background-color: var(--mp-primary-color) !important;
  --amplify-components-button-link-color: var(--mp-secondary-color) !important;
}

h2, .h2 {
  font: var(--mp-font-sans-serif);
  font-weight: 400;
  font-size: 30px;
  line-height: 1.8;
  color: white;
}

.App-logo {
  padding: 1rem;
  max-height: 5rem;
  pointer-events: none;
}

.Header {
  padding: 1rem 1rem 5rem 1rem;
  min-height: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

a {text-decoration: none;}

.PageNotFound, .Landingpage, .UserManagementView, .WatermeterImport, .CitizenImport, .Dashboard {padding: 1rem;}
.UserManagementView .dx-datagrid .dx-link {color: var(--mp-primary-color);}
.UserManagementView .dx-pager .dx-page-sizes .dx-selection, .UserManagementView .dx-pager .dx-pages .dx-selection {
  background-color: var(--mp-primary-color);
}

.UserManagementView .userStatus.active {color: green;}
.UserManagementView .userStatus.deactivated {color: darkorange;}
.UserManagementView .userStatus.removed {color: red;}

.centerHor{margin: auto; width: fit-content;}
.Button.center {
  width:fit-content;
  margin:auto;
}

@media screen and (max-width: 40rem) {
}